import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PlanListComponent } from './pages/plan/plan-list/plan-list.component';
import { PlanDetailComponent } from './pages/plan/plan-detail/plan-detail.component';
import { RecipeDetailComponent } from './pages/plan/recipe-detail/recipe-detail.component';

const routes: Routes = [
  { path: ':customerId', component: PlanListComponent},
  { path: ':customerId/plan/:planId', component: PlanDetailComponent},
  { path: ':customerId/plan/:planId/rezept/:planRezeptId', component: RecipeDetailComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
