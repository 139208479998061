import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CrudService } from '../../../services/crud.service';
import { UiService } from '../../../services/ui.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-recipe-detail',
  templateUrl: './recipe-detail.component.html',
  styleUrls: ['./recipe-detail.component.css']
})
export class RecipeDetailComponent implements OnInit {
  customerComment: string;
  nutritionPlanId: string;
  plan;
  recipe;
  customerId: string;
  recipeId: string;
  dayId: string;

  constructor(private route: ActivatedRoute, private crudService: CrudService, private uiService: UiService) { }

  ngOnInit() {
    this.uiService.takePhoto.subscribe(() => { this.takePhoto(); });
    this.uiService.showCamera = true;
    this.route.params.subscribe( params => {
      this.customerId = params.customerId.valueOf();
      this.dayId = params.planRezeptId.split('&&')[0];
      this.recipeId = params.planRezeptId.split('&&')[1];
      this.nutritionPlanId = params.planId.valueOf();
      this.crudService.getCustomerNutritionPlan(this.nutritionPlanId).subscribe(plan => {
        this.recipe = plan.day[this.dayId].meals[this.recipeId];
        console.log(this.recipe);
      });
    });

    this.crudService.getCustomerNutritionPlan(this.nutritionPlanId).subscribe(async plan => {
      this.plan = plan;
    });
  }

  takePhoto() {

  }

  async submitComment(form: NgForm) {
    const updatedComment = this.plan.day[this.dayId].meals[this.recipeId].comment = form.value.customerComment;
    try {
      await this.crudService.addCommentToRecipeOfNutritionPlan(this.nutritionPlanId, { ...this.plan, updatedComment });
      console.log('Kommentar hinzugefügt');
      this.uiService.showSimpleSnackbarSuccess('Kommentar hinzugefügt', 'OK');
    } catch (e) {
      console.log(e);
    }

  }

}
