import { EventEmitter, Injectable, OnDestroy, OnInit, Output } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatSnackBar } from '@angular/material';

import { Subject, Subscription } from 'rxjs';

interface Color {
  backgroundColor: string;
  borderColor: string;
  textColor: string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class UiService implements OnInit, OnDestroy {
  private loadingSubscription: Subscription;
  public loading = new Subject<boolean>();
  public loadingState = false;

  showCamera = false;
  @Output() takePhoto = new EventEmitter();

  colors: Color[] = [
    {
      backgroundColor: '#F5BAA8',
      borderColor: '#FC6E51',
      textColor: '#434A54',
      title: 'Rot'
    },
    {
      backgroundColor: '#FEE5AD',
      borderColor: '#FFCE54',
      textColor: '#434A54',
      title: 'Gelb'
    },
    {
      backgroundColor: '#D5E5B4',
      borderColor: '#A0D468',
      textColor: '#434A54',
      title: 'Grün'
    },
    {
      backgroundColor: '#B0E2D6',
      borderColor: '#48CFAD',
      textColor: '#434A54',
      title: 'Türkis'
    },
    {
      backgroundColor: '#B0DFF1',
      borderColor: '#4FC1E9',
      textColor: '#434A54',
      title: 'Hellblau'
    },
    {
      backgroundColor: '#B4CEF1',
      borderColor: '#5D9CEC',
      textColor: '#434A54',
      title: 'Dunkelblau'
    },
    {
      backgroundColor: '#D2CBF4',
      borderColor: '#AC92EC',
      textColor: '#434A54',
      title: 'Violett'
    },
    {
      backgroundColor: '#EFC9E0',
      borderColor: '#EC87C0',
      textColor: '#434A54',
      title: 'Rosa'
    },
  ];

  titlesV = [
    'DI',
    'Dipl.-Ing.',
    'Mag. med. vet.',
    'Dr. med. univ.',
    'Dr. med. dent.',
    'Mag. arch.',
    'Mag. rer. nat.',
    'Mag. pharm.',
    'Mag. phil.',
    'Mag. iur.',
    'Mag. rer. soc. oec.',
    'Mag. theol.',
    'Mag.',
    'Dr.',
    'Dr. nat. techn.',
    'Dr. med. univ. et scient. med.',
    'Dr. scient. med.',
    'Dr. mont.',
    'Dr. rer. nat.',
    'Dr. phil.',
    'Dr. phil. fac. theol.',
    'Dr. iur.',
    'Dr. rer. soc. oec.',
    'Dr. techn.',
    'Dr. theol.',
    'Dr. med. vet.',
    'Dr. med. dent.et scient. med.',
    'Prof.'
  ];

  titlesN = [
    'BA',
    'BEng',
    'BSc',
    'Bakk. phil.',
    'Bakk. rer. soc. oec.',
    'M.A.I.S',
    'MA',
    'LLM',
    'MSc',
    'PhD',
    'M.E.S',
    'MAS',
    'MBA',
    'M.B.L.',
    'MIB',
    'MPOS',
    'MP'
  ];

  einheiten = [
    'Stück',
    'Gramm',
    'Kilogramm',
    'Mililiter',
    'Liter'
  ];

  krankenkassen = [
    'WGKK',
    'BVA',
    'SVA',
    'KFA',
    'Privat'
  ];

  timesOptions = [
    '00:00', '00:15', '00:30', '00:45', '01:00', '01:15', '01:30', '01:45', '02:00', '02:15', '02:30', '02:45',
    '03:00', '04:15', '04:30', '04:45', '05:00', '05:15', '05:30', '05:45', '06:00', '06:15', '06:30', '06:45',
    '07:00', '07:15', '07:30', '07:45', '08:00', '08:15', '08:30', '08:45', '09:00', '09:15', '09:30', '09:45',
    '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45', '12:00', '12:15', '12:30', '12:45',
    '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45', '15:00', '15:15', '15:30', '15:45',
    '16:00', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45', '18:00', '18:15', '18:30', '18:45',
    '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45', '21:00', '21:15', '21:30', '21:45',
    '22:00', '22:15', '22:30', '22:45', '23:00', '23:15', '23:30', '23:45', '24:00'];

  constructor(public media: MediaObserver,
    public snackBar: MatSnackBar) {
    this.loadingSubscription = this.loading.subscribe(loading => {
      // steTimeout -> ugly workAround for ExpressionChangedAfterItHasBeenCheckedError
      // this is eventLoop issue
      setTimeout(() => this.loadingState = loading, 0);
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }

  showSimpleSnackbar(text, action?) {
    if (this.media.isActive('xs')) {
      this.snackBar.open(text, action, {
        panelClass: 'snackbarSemmily',
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(text, action, {
        panelClass: 'snackbarSemmily',
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
      });
    }
  }

  showSimpleSnackbarSuccess(text, action?) {
    if (this.media.isActive('xs')) {
      this.snackBar.open(text, action, {
        panelClass: 'snackbarSemmilySuccess',
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(text, action, {
        panelClass: 'snackbarSemmilySuccess',
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
      });
    }
  }

  showSimpleSnackbarError(text, action?) {
    if (this.media.isActive('xs')) {
      this.snackBar.open(text, action, {
        panelClass: 'snackbarSemmilyError',
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(text, action, {
        panelClass: 'snackbarSemmilyError',
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
      });
    }
  }

  showSimpleSnackbarAlert(text, action?) {
    if (this.media.isActive('xs')) {
      this.snackBar.open(text, action, {
        panelClass: 'snackbarSemmilyAlert',
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(text, action, {
        panelClass: 'snackbarSemmilyAlert',
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
      });
    }
  }

  getPrimary(a, v) {
    const r = a.filter(i => i['primary'] === true);
    return r.length > 0 ? r[0][v] : 'N/A';
  }

}
