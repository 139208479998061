import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';
import { FirestoreService } from './firestore.service';
import { first, switchMap, take } from 'rxjs/operators';

import * as firebase from 'firebase';


@Injectable({
  providedIn: 'root'
})
export class CrudService {

  nutritionPlans: Observable<any[]>;

  companyId: string;
  companyRole: number;

  constructor(
    private db: FirestoreService,
    private storage: AngularFireStorage,
    private http: HttpClient
  ) {}

  // NutritionPlan
  getCustomerNutritionPlans(customerId): Observable<any> {
    return this.db.colWithIds$(`nutritionPlans`, ref =>
      ref.where('customerId', '==', customerId).where('deleted', '==', false)
    );
  }

  getCustomerNutritionPlan(planId): Observable<any> {
    return this.db.doc$(`nutritionPlans/${planId}`);
  }

  addCommentToRecipeOfNutritionPlan(planId, newData): Promise<any> {
    return this.db.update(`nutritionPlans/${planId}`, newData);
  }
}
