import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CrudService } from '../../../services/crud.service';
import { UiService } from '../../../services/ui.service';

@Component({
  selector: 'app-plan-list',
  templateUrl: './plan-list.component.html',
  styleUrls: ['./plan-list.component.css']
})
export class PlanListComponent implements OnInit {

  customerId: string;
  customerNutritionPlans: any;

  constructor(private route: ActivatedRoute, private crudService: CrudService, private uiService: UiService) { }

  ngOnInit() {
    this.uiService.showCamera = false;

    this.route.params.subscribe(params => {
      this.customerId = params.customerId.valueOf();
      this.customerNutritionPlans = this.crudService.getCustomerNutritionPlans(this.customerId);
    });
  }

}
