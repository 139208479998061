import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CrudService } from '../../../services/crud.service';
import { UiService } from '../../../services/ui.service';

@Component({
  selector: 'app-plan-detail',
  templateUrl: './plan-detail.component.html',
  styleUrls: ['./plan-detail.component.css']
})
export class PlanDetailComponent implements OnInit {
  nutritionPlanId: string;
  customerId: string;
  plan;

  constructor(private route: ActivatedRoute, private crudService: CrudService, private uiService: UiService) { }

  ngOnInit() {
    this.uiService.showCamera = false;

    this.route.params.subscribe( params => {
      this.nutritionPlanId = params.planId.valueOf();
      this.customerId = params.customerId.valueOf();
      this.crudService.getCustomerNutritionPlan(this.nutritionPlanId).subscribe(plan => {
        console.log(plan);
        this.plan = plan;
      });
    });
  }

  getDailyNutrition(dayIndex, type) {
    if (type === 'kcal') {
      let kcal = 0;
      this.plan.day[dayIndex].meals.forEach(m => {
        if (m.recipe.nutrition && m.recipe.nutrition.kcal) {
          kcal = kcal + m.recipe.nutrition.kcal;
        }
      });
      return kcal;
    }
    if (type === 'eiweiss') {
      let eiweiss = 0;
      this.plan.day[dayIndex].meals.forEach(m => {
        if (m.recipe.nutrition && m.recipe.nutrition.eiweiss) {
          eiweiss = eiweiss + m.recipe.nutrition.eiweiss;
        }
      });
      return eiweiss;
    }
    if (type === 'fett') {
      let fett = 0;
      this.plan.day[dayIndex].meals.forEach(m => {
        if (m.recipe.nutrition && m.recipe.nutrition.fett) {
          fett = fett + m.recipe.nutrition.fett;
        }
      });
      return fett;
    }
    if (type === 'kh') {
      let kh = 0;
      this.plan.day[dayIndex].meals.forEach(m => {
        if (m.recipe.nutrition && m.recipe.nutrition.kohlenhydrate) {
          kh = kh + m.recipe.nutrition.kohlenhydrate;
        }
      });
      return kh;
    }
  }

}
